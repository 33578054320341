<template>
  <div class="mx-5 my-5">
    <v-row>
      <v-col md="12" class="d-flex" style="justify-content: space-between; align-items: center">
        <h2>{{ documentTypeLabel }}</h2>
      </v-col>
      <v-col md="3" class="py-0">
        <AutoCompleteField :label="$t('hr.loan.cash')" :cash="1" v-model="document.cash_account_id"
          endPoint="/accounting/account/auto-complete" outlined dense returnObject />
      </v-col>
      <v-col md="3" class="py-0">
        <v-menu ref="menu1" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
          max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :label="$t('date')" :dark="$store.state.isDarkMode" outlined dense persistent-hint prepend-icon="mdi-calendar" v-bind="attrs"
              v-on="on" v-model="documentDate"></v-text-field>
          </template>
          <v-date-picker v-model="documentDate" no-title @input="dateMenu = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col md="3" class="py-0">
        <v-text-field :label="$t('Document Number')" outlined dense v-model="document.serial"></v-text-field>
      </v-col>
      <v-col md="3" class="py-0">
        <v-autocomplete :label="$t('Tax Code')" outlined dense color="#757575" item-text="code" item-value="id"
          :items="taxes" v-model="document.tax_id" clearable @input="matchMaterialTax_IdHandler"></v-autocomplete>
      </v-col>
      <v-col md="3" class="py-0">
        <AutoCompleteField :label="$t('Tax Account')" outlined dense v-model="document.tax_account_id"
          endPoint="/accounting/account/auto-complete" returnObject clearable @input="matchMaterialTax_AccHandler"/>
      </v-col>
      <v-col md="3" class="py-0">
        <v-text-field :label="$t('Tax Percent')" v-model="document.tax_percent" type="number" outlined
          @input="matchMaterialTax_PercentHandler" dense></v-text-field>
      </v-col>
      <v-col md="6" class="py-0">
        <v-text-field :label="$t('notes')" v-model="document.notes" outlined dense></v-text-field>
      </v-col>
      <v-col md="12" class="py-0">
        <v-row>
          <v-container id="buffet-table" fluid tag="section">
            <v-simple-table v-if="document.table_view.length" :dark="$store.state.isDarkMode" style=" width: max-content;">
              <tr>
                <th v-if="document.table_view[0].value" id="idAutoComp">{{$t('deposit')}}</th>
                <th v-if="document.table_view[1].value" id="idAutoComp">{{$t('pay')}}</th>
                <th v-if="document.table_view[2].value" id="idAutoComp">{{$t('cost center')}}</th>
                <th v-if="document.table_view[3].value" id="idAutoComp">{{$t('hr.employee.check.account')}}</th>
                <th v-if="document.table_view[6].value" id="idAutoComp">{{ $t('Tax Code') }}</th>
                <th v-if="document.table_view[8].value" id="idPrice">{{ $t('Tax Percent') }}</th>
                <th v-if="document.table_view[9].value" id="idPrice">{{ $t('Included') }}</th>
                <th v-if="document.table_view[10].value" id="idPrice">{{ $t('Tax Value') }}</th>
                <th v-if="document.table_view[7].value" id="idAutoComp">{{ $t('Tax Account') }}</th>
                <th v-if="document.table_view[5].value" id="idPrice">{{ $t('Final Value') }}</th>
                <th v-if="document.table_view[4].value" id="idAutoComp">{{ $t('explain') }}</th>
                <th></th>
              </tr>
              <v-fade-transition mode="out-in" :group="true" tag="tbody">
                <tr v-for="(material, ix) in voucher_entriesD" :key="ix">
                  <td v-if="document.table_view[0].value">
                    <v-text-field full-width dense hide-details flat type="number" color="#757575"
                      @keydown.enter="ToNextInput" v-model.number="material.receipt" :disabled="material.disableReceipt"
                      @input="disablePay($event, material)"></v-text-field>
                  </td>
                  <td v-if="document.table_view[1].value">
                    <v-text-field full-width dense hide-details flat type="number" color="#757575"
                      @keydown.enter="ToNextInput" v-model.number="material.pay" :disabled="material.disablePay"
                      @input="disableReceipt($event, material)"></v-text-field>
                  </td>
                  <td v-if="document.table_view[2].value">
                    <AutoCompleteField label="" v-model="material.cost_center_id" returnObject
                      endPoint="/accounting/cost-center/auto-complete" />
                  </td>
                  <td v-if="document.table_view[3].value">
                    <AutoCompleteField label="" v-model="material.account_id" returnObject
                      endPoint="/accounting/account/auto-complete" />
                  </td>
                  <td v-if="document.table_view[6].value">
                    <v-autocomplete full-width dense hide-details flat :items="taxes" item-text="code" item-value="id"
                      v-model="material.tax_id"></v-autocomplete>
                  </td>
                  <td v-if="document.table_view[8].value">
                    <v-text-field full-width dense hide-details type="number" v-model="material.tax_percent"
                      @keydown.enter="ToNextInput" flat></v-text-field>
                  </td>
                  <td v-if="document.table_view[9].value" class="justify-center">
                    <v-checkbox :dark="$store.state.isDarkMode" v-model="material.is_tax_included" :true-value="1"
                      :false-value="0" class="mx-4 mb-4" hide-details></v-checkbox>
                  </td>
                  <td v-if="document.table_view[10].value"> {{ totalTaxI(material) }} </td>
             
                  <td v-if="document.table_view[7].value">
                    <AutoCompleteField label="" dense v-model="material.tax_account_id"
                      endPoint="/accounting/account/auto-complete" returnObject @input="ToNextInput" />
                  </td>
                  <td v-if="document.table_view[5].value && material.type != 'PAY'">-{{ totalValue(material) }} </td>
                  <td v-if="document.table_view[5].value && material.type == 'PAY'"> {{ totalValue(material) }} </td>
                  <td v-if="document.table_view[4].value">
                    <v-text-field full-width dense hide-details flat color="#757575" @keydown.enter="addNewVoucherEntries"
                      v-model="material.desc"></v-text-field>
                  </td>
                  <td>
                    <v-icon @click="deleteRow(ix)" color="red"
                      style="border: 1px solid red; border-radius: 50%">mdi-minus</v-icon>
                  </td>
                </tr>
              </v-fade-transition>
            </v-simple-table>
          </v-container>

        </v-row>
      </v-col>
      <v-col class="py-10" md="4">
        <v-btn color="blue" block @click="createNewDocument" :loading="submitting">{{
          isNew ? $t('add') : $t('edit') }}</v-btn>
      </v-col>
      <v-col class="py-10" md="1">
        <v-btn v-if="!isNew" color="green" @click="showAttachment">
          <v-icon class="white--text" size="20">mdi-paperclip</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-col md="12">
      <v-row>
        <v-col md="3" v-if="document.table_view[0].value">
          <v-text-field :value="totalReceipts()" readonly outlined dense :label="$t('Total Receipts')"></v-text-field>
        </v-col>
        <v-col md="3" v-if="document.table_view[0].value">
          <v-text-field :value="totalReceiptsAfterTax()" readonly outlined dense
            :label="$t('Total Receipts After Tax')"></v-text-field>
        </v-col>
        <v-col md="3" v-if="document.table_view[1].value">
          <v-text-field :value="totalPayments()" readonly outlined dense :label="$t('Total Payments')"></v-text-field>
        </v-col>
        <v-col md="3" v-if="document.table_view[1].value">
          <v-text-field :value="totalPaymentsAfterTax()" readonly outlined dense
            :label="$t('Total Payments After Tax')"></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col md="12">
      <v-row>
        <v-col class="justify-end" md="3">
          <v-text-field :value="finalValue()" readonly outlined dense :label="$t('Final Value')"></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog width="700" v-model="dialogAttachment">
      <v-card class="">
        <v-card-title class="justify-center">
          <h2>{{ }}</h2>
        </v-card-title>
        <v-card-text class="d-flex flex-column justify-center align-center">
          <div v-for="attachment in document.attachments" :key="attachment.id" class="add-user__chip1">
            <v-row style="font-weight: bold;">
              <v-col md="1"></v-col>
              <v-col md="4">{{ attachment.user.name }}</v-col>
              <v-col md="7">{{ attachment.date }}</v-col>
            </v-row>
            <v-row>
              <v-col md="4">
                <div class="cardd">
                  <div>
                    <v-row>
                      <v-col md="9">
                        <img class="preview-img" :src="attachment.path" />
                      </v-col>
                      <v-col md="3">
                        <v-icon v-if="returnAbility('voucher:delete:attachment')" color="red" @click="() => {
                          deleteAttachmentDialog = true;
                          attachmentId = attachment.id;
                        }
                          ">mdi-delete</v-icon>
                        <div class="mt-5">
                          <FileComponent :Id="attachment.id"></FileComponent>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  <div>
                    <v-row>
                      <v-col md="9">
                        <div class="test" :title="attachment.name">
                          <span>
                            {{ attachment.name }} -
                            {{ Math.round(attachment.size / 1000) + "kb" }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <app-attachment-modal path="voucher" @fetchData="showAttachment"></app-attachment-modal>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteAttachmentDialog" width="500">
      <v-card>
        <v-card-title class="d-flex justify-center">
          <h2>? {{ $t("are you sure delete ") }}</h2>
        </v-card-title>
        <v-card-actions class="py-10 justify-center">
          <v-btn color="green" class="white--text" depressed @click="deleteAttachment(attachmentId)"
            :loading="deleteBtnLoading">{{ $t("submit") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row><v-col></v-col></v-row>
    <v-row><v-col></v-col></v-row>
    <v-row><v-col></v-col></v-row>
    <v-row><v-col></v-col></v-row>
    <Footer v-if="!isNew" path="voucher" class="d-flex justify-end" />
  </div>
</template>

<script>
import axios from "axios";
import AutoCompleteField from "../../components/core/AutoCompleteField.vue";
import Footer from '../invoices/Footer.vue';
import attachmentModal from "../invoices/attachmentModal.vue";
import FileComponent from "../teachers/download.vue";
export default {
  components: { AutoCompleteField, Footer, FileComponent, appAttachmentModal: attachmentModal, },
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {     
      deleteAttachmentDialog: false,
      attachmentId: null,
      timeMenu: false,
      timeMenuRef: null,
      documentDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      documentTime: `${String(new Date().getHours()).length == 1
        ? `0${new Date().getHours()}`
        : new Date().getHours()
        }:${String(new Date().getMinutes()).length == 1
          ? `0${new Date().getMinutes()}`
          : new Date().getMinutes()
        }`,
      documentTypeLabel: null,
      fetchingMetadata: false,
      documentMetadata: null,
      voucher_entriesD: [],
      dateMenu: false,
      document: {
        attachments: [],
        date: null,
        serial: null,
        notes: null,
        voucher_type_id: this.$route.query.document_id,
        cash_account_id: null,
        voucher_entries: [],
        total_amount: 0,
        tax_id: null,
        // tax_account_id: null,
        tax_percent: 0,
        table_view: [],
      },
      tax_in_account_id: null,
      tax_out_account_id: null,
      total_value: 0,
      taxes: [],
      submitting: false,
      attachmentId: false,
      dialogAttachment: false,
      deleteBtnLoading: false,
    };
  },
  methods: {
    finalValue() {
      let val = 0;
      if (this.voucher_entriesD.length) {
      this.voucher_entriesD.forEach(el => {
        if (el.total_value) {
          if(el.type!="PAY"){
            val -= el.total_value;
          } else {
            val += el.total_value;
          }
        }
      });}
      if (val) return parseFloat(val.toFixed(2));
      else return 0;
    },
    totalPayments() {
      let val = 0;
      if (this.voucher_entriesD.length) {
      this.voucher_entriesD.forEach(el => {
        if (el.type == "PAY" && el.amount) {
          val += el.amount;
        }
      });}
      if (val) return parseFloat(val.toFixed(2));
      else return 0;
    },
    totalPaymentsAfterTax() {
      let val = 0;
      if (this.voucher_entriesD.length) {
      this.voucher_entriesD.forEach(el => {
        if (el.type == "PAY" && el.amount) {
          val += el.amount;
        if (!el.is_tax_included && el.tax_value) {
          val += el.tax_value;
        }}
      });}
      if (val) return parseFloat(val.toFixed(2));
      else return 0;
    },
    totalReceipts() {
      let val = 0;
      if (this.voucher_entriesD.length) {
      this.voucher_entriesD.forEach(el => {
        if (el.type == "RECEIPT" && el.amount) {
          val += el.amount;
        }
      });}
      if (val) return parseFloat(val.toFixed(2));
      else return 0;
    },
    totalReceiptsAfterTax() {
      let val = 0;
      if (this.voucher_entriesD.length) {
      this.voucher_entriesD.forEach(el => {
        if (el.type == "RECEIPT" && el.amount) {
          val += el.amount;
        
        if (!el.is_tax_included && el.tax_value) {
          val += el.tax_value;
        }}
      });}
      if (val) return parseFloat(val.toFixed(2));
      else return 0;
    },
    totalTaxI(mat) {
      let total = mat.amount;
      if (mat.is_tax_included) {
        mat.tax_value = (total * mat.tax_percent) / (100 + parseFloat(mat.tax_percent));
      }
      else {
        mat.tax_value = total * mat.tax_percent / 100
      }
      if (mat.tax_value) return parseFloat(mat.tax_value.toFixed(2));
      else return 0;
    },
    totalValue(mat) {
      if (!mat.is_tax_included && mat.tax_value) {
        mat.total_value = mat.amount + mat.tax_value;
      } else {
        mat.total_value = mat.amount;
      }
    // if (mat.type == "PAY" && mat.total_value) { mat.total_value = (-1 * mat.total_value); }
      if (mat.total_value) return parseFloat(mat.total_value.toFixed(2));
      else return 0;
    },
    async deleteAttachment(id) {
      try {
        this.deleteBtnLoading = true;
        await axios.delete(`voucher/attachment/${id}`);
        this.deleteAttachmentDialog = false;
        this.$Notifications(
              this.$t('delete success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.showAttachment();
      } catch (err) {
      } finally {
        this.deleteBtnLoading = false;
      }
    },
    async showAttachment() {
      const res = await axios.get(`voucher/attachment/${this.$route.params.id}`)
      this.document.attachments = res.data.attachment;
      this.dialogAttachment = true;
    },
    deleteRowI(index) {
      if (this.installments.length == 1) return;
      this.installments.splice(index, 1);
    },
    async getDocumentTypeMetadata() {
      try {
        this.fetchingMetadata = true;
        const result = await axios.get(`/accounting/voucher-type/meta-data/${this.$route.query.document_id}`);
        this.documentMetadata = result.data.data;
        this.document.serial = result.data.next_serial;
        this.document.cash_account_id = this.documentMetadata.cash_account;
        this.tax_in_account_id = this.documentMetadata.tax_in_account;
        this.tax_out_account_id = this.documentMetadata.tax_out_account;
        this.documentTypeLabel = this.documentMetadata.name;
        this.document.table_view = this.documentMetadata.table_view;
      } finally {
        this.fetchingMetadata = false;
      }
    },
    disablePay(Value, row) {
      if (Value) {
        row.disablePay = true;
        row.type = "RECEIPT";
        row.amount = row.receipt;
        row.tax_account_id = this.tax_out_account_id;
      } else {
        row.disablePay = false;
        row.type = "PAY";
        row.amount = row.pay;
        row.tax_account_id = this.tax_in_account_id;
      }
    },
    disableReceipt(Value, row) {
      if (Value) {
        row.disableReceipt = true;
        row.type = "PAY";
        row.amount = row.pay;
        row.tax_account_id = this.tax_in_account_id;
      } else {
        row.disableReceipt = false;
        row.type = "RECEIPT";
        row.amount = row.receipt;
        row.tax_account_id = this.tax_out_account_id;
      }
    },
    async createNewDocument() {
      try {
        this.submitting = true;
        this.document.voucher_entries = this.voucher_entriesD;
        let params = {
          ...this.document,
          cash_account_id: this.document.cash_account_id?.id,
          date: `${this.documentDate}`,
          voucher_entries: this.document.voucher_entries.map((v) => ({
            ...v,
            account_id: v.account_id?.id,
            tax_account_id: v.tax_account_id?.id,
            cost_center_id: v.cost_center_id?.id,
          })),
        }
        if (this.isNew) {
          await axios.post("/accounting/voucher", params);
        } else {
          await axios.put(`/accounting/voucher/${this.$route.params.id}`, params);
        }
        this.$router.go(-1);
      } finally {
        this.submitting = false;
      }
    },
    documentTypeFormatter(type) {
      return [
        {
          label: "يومية صندوق",
          value: "DAILY",
        },
      ].find((el) => el.value == type).label;
    },
    deleteRow(index) {
      if (this.voucher_entriesD.length == 1) return;
      this.voucher_entriesD.splice(index, 1);
    },
    addNewVoucherEntries(focus = true) {
      this.voucher_entriesD.push({
        pay: null,
        disablePay: null,
        receipt: null,
        disableReceipt: null,
        cost_center_id: null,
        account_id: null,
        desc: null,
        type: null,
        amount: null,
        tax_account_id: null,
        tax_id: this.document.tax_id,
        tax_percent: this.document.tax_percent,
        tax_value: 0,
        is_tax_included: 0,
        total_value: 0,
      });
      if (focus) {
        setTimeout(() => {
          this.ToNextInput();
        }, 0);
      }
    },
    ToNextInput() {
      const inputs = Array.prototype.slice.call(
        document.querySelectorAll("table input:not([type=hidden])")
      );
      const currInput = document.activeElement;
      const currInputIndex = inputs.indexOf(currInput);
      const nextinputIndex = currInputIndex + 1;
      const input = inputs[nextinputIndex];
      input.focus();
    },
    matchMaterialTax_IdHandler() {
     this.taxes.forEach((el)=>{
      if(el.id == this.invoice.tax_id){
        this.invoice.tax_percent = el.percent;
        this.matchMaterialTax_PercentHandler();
      }
     });
      this.invoice.materials.forEach((mat) => {
        mat.tax_id = this.invoice.tax_id;
      });
    },
    matchMaterialTax_PercentHandler() {
      if (this.voucher_entriesD.length) {
      this.voucher_entriesD.forEach(mat => {
        mat.tax_percent = this.document.tax_percent;
      });}
    },
    matchMaterialTax_AccHandler() {
      if (this.voucher_entriesD.length) {
      this.voucher_entriesD.forEach(mat => {
        // mat.tax_account_id = this.document.tax_account_id;
      });}
    },
  },
  async created() {
    let params = { paginated: 'false', };
    if(this.returnAbility('tax:index')){
        let res = await axios.get(`/accounting/tax`, { params });
        this.taxes = res.data.data;
      }
    if (this.isNew) {
      await this.getDocumentTypeMetadata();
      this.addNewVoucherEntries(false);
    } else {
      const result = await axios.get(`/accounting/voucher/${this.$route.params.id}`);
      const dataTemp = result.data.data;
      const dateSplit = dataTemp.date.split(" ");
      this.documentDate = dateSplit[0];
      this.document.voucher_entries = dataTemp.voucher_entries.map((el) => ({
        ...el,
        tax_account_id: el.tax_account,
      }));
      this.voucher_entriesD = dataTemp.voucher_entries.map((el) => ({
        ...el,
        tax_account_id: el.tax_account,
        pay: null,
        disablePay: false,
        receipt: null,
        disableReceipt: false,
        cost_center_id: el.cost_center_id,
        account_id: el.account_id,
      }));
      for (let i = 0; i < dataTemp.voucher_entries.length; i++) {
        if (dataTemp.voucher_entries[i].type == "PAY") {
          this.voucher_entriesD[i].pay = dataTemp.voucher_entries[i].amount;
          this.voucher_entriesD[i].disablePay = false;
          this.voucher_entriesD[i].receipt = null;
          this.voucher_entriesD[i].disableReceipt = true;
        }
        if (dataTemp.voucher_entries[i].type == "RECEIPT") {
          this.voucher_entriesD[i].receipt = dataTemp.voucher_entries[i].amount;
          this.voucher_entriesD[i].disableReceipt = false;
          this.voucher_entriesD[i].pay = null;
          this.voucher_entriesD[i].disablePay = true;
        }
      }
      this.document.serial = dataTemp.serial;
      this.document.notes = dataTemp.notes;
      this.document.cash_account_id = dataTemp.cash_account;
      this.documentTypeLabel = dataTemp.voucher_type.name;
      this.document.tax_id = dataTemp.voucher_type.tax_id;
      this.document.table_view = dataTemp.voucher_type.table_view;
    }
  },
};
</script>

<style scoped lang="scss">
table {
  border-collapse: collapse;
  table-layout: fixed;
}

.tableId {
  border-radius: 10%;
}

td,
th {
  padding: 0px 10px;
  border-collapse: collapse;
  border-radius: 10%;
}

table,
th,
td {
  border: 1px solid #000;
  border-collapse: collapse;
  border-radius: 10%;
}

#idAutoComp {
  padding-left: 70px;
  padding-right: 70px;
}

#idPrice {
  padding-left: 40px;
  padding-right: 40px;
}

#buffet-table {
  border: 1px solid rgb(216 216 221 / 53%);
  font-size: 1rem;
}

#buffet-table th {
  color: #909399;
  border: 1px solid rgb(216 216 221 / 53%);
  font-size: 1rem;
  text-align: center;
  width: max-content;

}

#buffet-table tr {
  transition: 0.5s;
}

#buffet-table td {
  border: 1px solid rgb(216 216 221 / 53%);
  text-align: center;
  font-size: 1.1rem;
  width: max-content;
}

.columnSelector:hover {
  border-right: 2px solid blue;
}

.add-user__chip1 {
  border: 1px solid #757575;
  border-radius: 10px;
  margin: 5px 0;
  width: 100%;
}

.cardd {

  border: 1px solid #a2a2a2;
  width: 100%;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 4px 4px;
}

.cardd .preview-img {
  width: 100%;
}
</style>
